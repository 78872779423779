@import 'bootstrap/scss/functions';
@import 'scss/variables';

// bootstrap overwrite
@import 'scss/bootstrap_utilities_overwrite';
//
//@import 'bootstrap/scss/bootstrap';

// Config

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/accordion";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
//@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
//@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// END Config




//
//@import 'lib/circle-entity';
@import 'scss/button';
//@import 'lib/flag-overwrite';
@import 'scss/utility';
//@import 'lib/key-value';
//@import 'lib/stats-table';
//@import 'lib/toast';
//@import 'lib/form';
//@import 'lib/mat-theme';
//@import 'lib/mat-table';
@import 'scss/minimal-table';
//@import 'lib/container';
//@import 'lib/tabs';
//@import 'lib/color-helper';
//
//// ng prime overwrite
//@import 'lib/p-checkbox';
//@import 'lib/p-form';
//@import 'lib/p-menu';
//@import 'lib/p-tables';
//@import 'lib/storybook';
//
//// ng bootstrap (ngb) overwrite
//@import 'lib/ngb-overwrite';
//
//// fonts
@import 'scss/poppins';
@import 'scss/inter';
@import 'scss/roboto';
@import 'all';

body {
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    padding-top: 52px;
    padding-bottom: 54px;
    background-image: url("../static/kraken.svg"), linear-gradient(#f8f9fa 0%, #d8e1f1 100%) !important;

    min-height: 100vh;

}

.cc22-container {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background: $white;
    padding: 2rem;
    max-width: 950px;
    margin: 0 auto;
    border-radius: 5px;
}

.header-trenner {
    position: relative;
    margin-left: 1rem;
    padding-left: 1.2rem;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 1px;
        height: 20px;
        background: rgba(white, 0.6);
    }
}

.navbar {
    height: 52px;
}

.upload-checkmark {
    svg {
        fill: white;
    }

    display: none;
    margin-left: auto;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background: $success;
    align-items: center;
    justify-content: center;
}

.info-icon {
    margin-top: 15px;
    margin-left: 4px;
}

.bluedrop-heading {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

.file-item-html.uploaded {
    .download-btn {
        display: none!important;
    }

    .upload-checkmark {
        display: flex!important;
    }
}

.cc22-footer {
    height: 54px;

    .link {
        color: rgba(white, 0.6);
        &:hover {
            color: rgba(white, 1);
        }
    }
}

.file-icon-bg {
    background: #1a1e23;
    color: white;
    position: absolute;
    top: 22px;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 600;
}

.timer {
    background: #D9DCE2;
    padding: 4px;
    border-radius: 5px;
    font-feature-settings: 'calt' 0, 'liga' 0, 'ccmp' 0, 'tnum' 1;
}

.code-input {
    font-size: 20px;
    letter-spacing: 0.2em;
    font-family: 'Roboto Mono';

    &.form-control::placeholder {
        color: #a2a9b4;
    }
}
