@import "variables";

$mt-color: #E4E8F0;
$mt-background: #F0F2F5;

.minimal-table-alt {
    .minimal-table-label {
        background: $mt-background;
        min-height: 40px;
        padding: 0.2rem 1rem;
        display: flex;
        align-items: center;
        font-weight: 350;

        &:not(:last-child) {
            border-bottom: 1px solid white;
        }
    }

    .minimal-table-cell {
        border-top: 1px solid $mt-color;
        min-height: 40px;
        padding: 0.2rem 1rem;
        display: flex;
        align-items: center;


    }

    .minimal-table-row {
        display: grid;
        grid-template-columns: 240px auto;

        &:last-child {
            .minimal-table-cell {
                border-bottom: 1px solid $mt-color;
            }
            .minimal-table-label {
                border-bottom: 1px solid $mt-background;
            }
        }

        &:not(:last-child) {
            .minimal-table-label {
                border-bottom: 1px solid white;
            }
        }
    }
}
