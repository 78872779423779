@import 'variables';

.font-poppins {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.font-body {
    font-family: $font-family-sans-serif;
}

$font-sizes: (
    10: (
        1rem * 0.625,
    ),
    11: (
        1rem * 0.625,
    ),
    12: (
        1rem * 0.75,
    ),
    14: (
        1rem * 0.875,
    ),
    16: (
        1rem * 1,
    ),
    18: (
        1rem * 1.125,
    ),
    20: (
        1rem * 1.25,
    ),
    22: (
        1rem * 1.375,
    ),
    24: (
        1rem * 1.5,
    ),
    28: (
        1rem * 1.75,
    ),
    32: (
        1rem * 2,
    ),
    36: (
        1rem * 2.25,
    ),
    40: (
        1rem * 2.5,
    ),
    48: (
        1rem * 3,
    ),
    60: (
        1rem * 3.75,
    ),
);

@each $key, $size in $font-sizes {
    .font-size-#{$key} {
        font-size: $size;
    }

    .fs-#{$key} {
        font-size: $size;
    }
}

$letter-spacings: (
    1: 0.01em,
    2: 0.02em,
    3: 0.03em,
    4: 0.04em,
    5: 0.05em,
    6: 0.06em,
);

@each $key, $size in $letter-spacings {
    .letter-spacing-#{$key} {
        letter-spacing: $size;
    }
}

.font-weight-350,
.fw-semilight,
.fw-350 {
    font-weight: 350 !important;
}

.font-weight-bold,
.fw-bold,
.fw-700 {
    font-weight: 700 !important;
}

.font-weight-semibold,
.fw-semi,
.fw-semibold,
.fw-600 {
    font-weight: 600 !important;
}

.font-weight-regular,
.fw-regular,
.fw-400 {
    font-weight: 400 !important;
}

.font-weight-medium,
.fw-medium,
.fw-500 {
    font-weight: 500 !important;
}

.font-tabular {
    font-feature-settings: 'calt' 0, 'liga' 0, 'ccmp' 0, 'tnum' 1;
}

.btn-text-primary {
    vertical-align: unset;
    padding: 0;
    $_color: $primary;
    color: $_color;

    &:hover {
        color: lighten($_color, 15%);
    }
}

.btn-icon-ghost-primary {
    vertical-align: unset;
    $_color: $primary;
    color: $_color;
    position: relative;
    padding: 0;
    height: 2em;
    width: 2em;
    border-radius: 50%;

    &:focus {
        // todo: make a good focus style
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        outline: 0;
        box-shadow: none;
        color: $_color;

        &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($_color, 0.15);
        }
    }
}

.btn-icon-ghost-warning {
    vertical-align: unset;
    $_color: $warning;
    color: $_color;
    position: relative;
    padding: 0;
    height: 2em;
    width: 2em;
    border-radius: 50%;

    &:focus {
        // todo: make a good focus style
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        outline: 0;
        box-shadow: none;
        color: $_color;

        &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($_color, 0.15);
        }
    }
}

.btn-icon-ghost-danger-muted {
    vertical-align: unset;
    $_color: $gray-500;
    color: $_color;
    position: relative;
    padding: 0;
    height: 2em;
    width: 2em;
    border-radius: 50%;

    &:focus {
        // todo: make a good focus style
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        outline: 0;
        box-shadow: none;
        color: $danger;

        &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($danger, 0.15);
        }
    }
}

.btn-icon-ghost-primary-muted {
    vertical-align: unset;
    $_color: $gray-500;
    color: $_color;
    position: relative;
    padding: 0;
    height: 2em;
    width: 2em;
    border-radius: 50%;

    &:focus {
        // todo: make a good focus style
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        outline: 0;
        box-shadow: none;
        color: $primary;

        &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($primary, 0.15);
        }
    }
}

.btn-icon-ghost-secondary {
    vertical-align: unset;
    $_color: $gray-500;
    color: $_color;
    position: relative;
    padding: 0;
    height: 2em;
    width: 2em;
    border-radius: 50%;

    &:focus {
        // todo: make a good focus style
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        outline: 0;
        box-shadow: none;
        color: $_color;

        &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($_color, 0.15);
        }
    }
}

.btn-icon-ghost-success {
    vertical-align: unset;
    $_color: $green;
    color: $_color;
    position: relative;
    padding: 0;
    height: 2em;
    width: 2em;
    border-radius: 50%;

    &:focus {
        // todo: make a good focus style
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        outline: 0;
        box-shadow: none;
        color: $_color;

        &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($_color, 0.15);
        }
    }
}

.btn-icon-ghost-fav {
    vertical-align: unset;
    color: $gray-400;
    position: relative;
    padding: 0;
    height: 2em;
    width: 2em;
    border-radius: 50%;

    &:focus {
        // todo: make a good focus style
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        outline: 0;
        box-shadow: none;
        color: $yellow;

        &::after {
            content: '';
            position: absolute;

            border-radius: 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($yellow, 0.15);
        }
    }

    &.active > i {
        color: $yellow;
        font-weight: 900;
    }
}

.btn-icon-ghost-light {
    vertical-align: unset;
    $_color: $white;
    color: $_color;
    position: relative;
    padding: 0;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;

    &:focus {
        // todo: make a good focus style
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        outline: 0;
        box-shadow: none;
        color: $_color;

        &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($_color, 0.15);
        }
    }
}

.btn-icon-ghost-danger {
    vertical-align: unset;
    $_color: $danger;
    color: $_color;
    position: relative;
    padding: 0;
    height: 2em;
    width: 2em;
    border-radius: 50%;

    &:focus {
        // todo: make a good focus style
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        outline: 0;
        box-shadow: none;
        color: $_color;

        &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($_color, 0.15);
        }
    }
}

.btn-text-secondary {
    vertical-align: unset;
    padding: 0;
    $_color: $secondary;
    color: $_color;

    &:hover {
        color: darken($_color, 15%);
    }
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.user-select-text {
    user-select: text;
}

.text-app {
    @extend .font-poppins;
    @extend .text-uppercase;
    font-weight: 600;
}

.text-magenta {
    color: $magenta !important;
}

.top-n1 {
    position: relative;
    top: -1px;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.rotate-transition {
    transition: transform 0.3s $ease-in-out-quart;
}

.g-fontsettings-calt-0 {
    font-feature-settings: 'calt' 0, 'ccmp', 'kern';
}

.modal-xxl {
    @extend .modal-xl;
    max-width: $modal-xxl;
}

.modal-xxxl {
    @extend .modal-xl;
    max-width: $modal-xxxl;
}

.border-orange,
.border-warning {
    border-color: $warning !important;
}

.bg-modal {
    background: $modal-header-background;
    color: $modal-header-font-color;

    cc22-ui-icon-close {
        button {
            color: $modal-header-font-color;
        }
    }
}

.no-selectable,
.user-select-none {
    user-select: none;
}

.rounded-tl-0 {
    border-top-left-radius: 0 !important;
}

.rounded-tr-0 {
    border-top-right-radius: 0 !important;
}

.rounded-bl-0 {
    border-bottom-left-radius: 0 !important;
}

.rounded-br-0 {
    border-bottom-right-radius: 0 !important;
}

.bg-gray-50 {
    background: $gray-50 !important;
}

.break-word {
    word-break: break-word;
}

.alert.alert-danger {
    color: darken($danger, 5%);
    background: lighten($danger, 40%);
    border: 1px solid lighten($danger, 30%);
}

.alert.alert-success {
    color: $success;
    background: lighten($success, 60%);
    border: 1px solid lighten($success, 35%);
}

.alert-info {
    background: $blue-100;
    border: 1px solid $blue-200;
    color: $blue-500;
}
