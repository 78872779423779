.btn-check:focus + .btn, .btn:focus {
    box-shadow: none;
}

.m-chips {
    background-color: $primary;
    border-radius: 3px;
    color: white;
    padding-left: 6px;
    padding-right: 0;

    &:hover {
        color: white;
    }
}

.m-close {
    opacity: 0.6;
    padding: 6px 8px 6px 6px;

    &:hover {
        opacity: 1;
    }
}

.badge-magenta {
    @extend .badge;
    background: #eb2c7d;
    color: #ffe9e9;
}

.btn-ghost-primary {
    vertical-align: unset;
    color: $primary;

    &:hover {
        color: $primary;
    }

    &:hover {
        background: rgba($primary, 0.1);
    }

    &:focus {
        box-shadow: none;
    }
}

.btn-ghost-black {
    @extend .btn-ghost-primary;
    color: $body-color;
    //
    &:hover {
        color: $body-color;
    }
}

.btn-ghost-secondary {
    @extend .btn-ghost-primary;
    padding: 0.375rem 0.75rem;
    color: $secondary;

    &:hover {
        color: $secondary;
    }

    &:enabled:hover {
        background: rgba($secondary, 0.1);
    }
}

.btn-display {
    color: #fff;
    transition: all 0.4s ease-in-out;
    text-align: center;
    border-radius: 5px;
    background: linear-gradient(to right, #04bef9 0%, $primary 100%) no-repeat;
    background-position: 50% 100%;
    background-size: 200% 100%;
    background-clip: padding-box;
    font-weight: 400;
    border: none;
    margin-bottom: 14px;
    transform-style: preserve-3d;
    padding: 10px 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.btn-display:hover {
    background-position: 0% 50%;
    color: #fff;
    transition: all 0.4s ease-in-out;
}


.btn-group {
    .btn-display {
        box-shadow: none;
    }
}

.btn-display-dropdown {
    background: #1874d2;
    color: rgba(white, 0.7);
    box-shadow: none;
    margin-bottom: 14px;
    padding: 10px 0.6rem;
    position: relative;
    margin-left: 0!important;
    transition: all 0.4s $ease-in-out-quart;

    &:hover {
        color: rgba(white, 1);
        background: #0f96eb;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -2px;
        width: 1px;
        height:100%;
        background: rgba(white, 0.2);
        z-index: 1;
    }
}
